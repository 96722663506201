import { config } from '../config';

const variables = {
    en: {
        connect_web3: `Connect to your ${config.NETWORK_NAME} ${config.NETWORK_TYPE} account`,
        web3_note: 'web3 power user? Your on-chain data will give you exclusive access',
        select_account: 'Select Account',
        keplr: 'Keplr',
        welcome: `Welcome to the ${config.NETWORK_NAME} community`,
        participate: 'Know your validators & interact with the community via governance proposals!',
        total_tokens: 'Total Tokens',
        available_tokens: 'Available Tokens',
        staked_tokens: 'Staked Tokens',
        un_staked_tokens: 'Unstaked Tokens',
        eligible_groups: 'Eligible Groups',
        connect_telegram: 'Connect your Telegram account',
        telegram_communities: 'Participate in exclusive Telegram communities, only for on-chain pros!',
        connect_to_telegram: 'Connect to your Telegram Account',
        connect: 'Connect',
        enter_telegram_username: 'Enter your Telegram username',
        will_receive_otp: 'You will receive an OTP which you have to enter using',
        confirm_username: 'Please confirm your username',
        otp_note1: 'To verify and continue, send a message to ',
        otp_note2: ' in the below format ',
        verify: 'Verify',
        copy: 'Copy',
        copy_otp: 'Copy OTP',
        disconnect: 'Disconnect',
        your_otp: 'Your OTP',
        dashboard: 'Dashboard',
        stake: 'Stake',
        vote: 'Vote',
        proposals: 'Proposals',
        stake_tokens: 'Stake Tokens',
        delegate: 'Delegate',
        un_delegate: 'Undelegate',
        re_delegate: 'Redelegate',
        all_validators: 'All Validators',
        staked_validators: 'Staked Validators',
        tokens: 'Tokens',
        done: 'Done',
        validator_details: 'Validator Details',
        all: 'All',
        active: 'Passed',
        pending: 'Pending',
        closed: 'Rejected',
        rewards: 'Rewards',
        claim: 'Claim',
        vesting_tokens: 'Vesting tokens',
        select_validator: 'Select the validator',
        view_all: 'View All',
        top_active_proposals: 'Top Active Proposals',
        no_data_found: 'No data found',
        success: 'Success',
        transaction_failed: 'Transaction Failed',
        transaction_processing: 'Transaction is in process. use high gas for quick transaction',
        claimed_success: 'CLAIMED TOKENS Successfully',
        vote_success: 'Vote Submitted Successfully',
        transaction_hash: 'Transaction Hash',
        delegator_address: 'Delegator Address',
        validator_address: 'Validator Address',
        approval_pending: 'Approval Pending...',
        connect_account: 'Account not connected. Please connect to wallet',
        connecting: 'connecting',
        cosmostation: 'Cosmostation',
    },
};

export default variables;
